.auth_containter {
  /* background-image: url("../../assets//authBack.png"); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* background-color: #067991; */
}
.auth_wrapper {
  padding: 64px;
}
.card_auth {
  max-width: 500px;
  width: 100%;
  box-shadow: 0px 0px 25px #dddddd;
  border-radius: 32px !important;
}
.card_auth_packages {
  max-width: 1312px;
  width: 100%;
  box-shadow: 0px 0px 25px #dddddd;
  border-radius: 32px !important;
}
img.auth_logo {
  max-width: 60px;
}
.header_card_auth {
  gap: 0.5rem;
}
.title_auth {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 52px;
  letter-spacing: 2px;
  text-transform: uppercase;
  /* color: #007991; */
}
.auth_desc {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  color: #060b19;
}
.auth_desc_text {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 180%;
  color: #a9a9a9;
}
.btn_login {
  background: #008cba;
}
.forgot-pass {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  /* or 25px */
  color: #626262;
  text-decoration: none;
}

.input_field {
  height: 61px;
  border: 1px solid #cacaca;
  border-radius: 8px;
}

.input_field_nik {
  height: 61px;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: url("../../assets/icons/NIKIcons.png") no-repeat 7px 7px;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 5%;
  padding-left: 15% !important;
}

.input_field_email {
  height: 61px;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: url("../../assets/icons/MailIcons.png") no-repeat 7px 7px;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 5%;
  padding-left: 15% !important;
}
.input_field_name {
  height: 61px;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: url("../../assets/icons/UserIconsDark.png") no-repeat 7px 7px;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 5%;
  padding-left: 15% !important;
}

.input_field_phone {
  height: 61px;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: url("../../assets/icons/PhoneIcons.png") no-repeat 7px 7px;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 5%;
  padding-left: 15% !important;
}
.input_field_pass {
  height: 61px;
  border: 1px solid #cacaca;
  border-radius: 8px;
  background: url("../../assets/icons/PasswordIcons.png") no-repeat 7px 7px;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 5%;
  padding-left: 15% !important;
}

.input_label {
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  align-items: center;
  color: #2c3131;
}

.create_account {
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  display: flex;
  align-items: center;
  color: #060b19;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-decoration: none;
}

@media screen and (max-width: 500px) {
  .auth_containter {
    /* background-image: url("../../assets//authBack.png"); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */
    padding: 24px;
  }
  .auth_wrapper {
    padding: 0px;
  }
  .title_auth {
    font-size: 16px;
  }
  .auth_desc {
    font-size: 16px;
  }
  .auth_desc_text {
    font-size: 14px;
  }
  .create_account {
    font-weight: 400;
    font-size: 12px;
    display: grid;
    /* flex-direction: column; */
  }
  .forgot-pass {
    font-size: 12px;
  }
  .input_label {
    font-size: 14px;
  }
  .input_field_nik {
    height: 50px;
  }
  .input_field_email {
    height: 50px;
  }
  .input_field_name {
    height: 50px;
  }
  .input_field {
    height: 50px;
  }
  .input_field_pass {
    height: 50px;
  }
  .input_field_phone {
    height: 50px;
  }
}
