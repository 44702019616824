.photo_left {
  max-height: 100%;
  height: auto;
  border: 2px solid;
  border-radius: 10px;
  border-color: #dde1e5;
}
.photo_left.notActive {
  max-height: 100%;
  height: 221px;
  border: 2px solid;
  border-radius: 10px;
  border-color: #dde1e5;
}

/* .input__file__wrapper_project {
  border: 1px solid #cacaca;
  height: 100%;
  max-width: 100%;
  border-radius: 10px;
  padding-left: 75px;
  padding-right: 75px;
  text-align: center;
}

.input__file__wrapper_project_default {
  border: 1px solid #cacaca;

  height: 100%;
  max-width: 100%;
  max-height: 390px;
  border-radius: 10px;
  padding-left: 75px;
  padding-right: 75px;
  text-align: center;
}

.cloud__browse__icon_project {
  width: 70px;
  height: 66.5px;
  contain: content;
}

.browse__file__button {
  width: 123px;
  height: 40px;
  border-radius: 10px;
  background-color: #060b19;
  border: 1px solid #060b19;
  color: white;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
} */
