.detail_card {
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  /* identical to box height, or 29px */

  color: #262323;
}
.detail_account_container {
  transition: width 0.3s ease-out;
}
.detail_card_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 180%;
}

.status_card_pending {
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  color: #dfd249;
}
.status_card_success {
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  color: #54b435;
}
.status_card_failed {
  font-weight: 600;
  font-size: 16px;
  line-height: 180%;
  color: #e25c5c;
}
