.packages_container {
  padding: 64px;
}

.title_packages {
  font-family: "Racing Sans One";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  /* or 130% */
  letter-spacing: 2px;
  text-transform: uppercase;

  color: #007991;
}
.alert_text {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 37px;

  color: #d50000;
}
.col-md-6.packages_option {
}

.packages_card {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.packages_card_header {
  font-weight: 700;
  font-size: 24.375px;
  line-height: 32px;
  color: #ffffff;
  background: #142657;
  border-top-left-radius: 15px !important;
  border-top-right-radius: 15px !important;
  padding: 16px;
}

.packages_card_text {
  font-weight: 400;
  font-size: 16px;
  color: #7a7a7a;
}

.price {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  color: #ff0000;
}
.btn_register_bpjs {
  background: #142657;
  color: white;
  border-radius: 10px;
}

@media screen and (max-width: 500px) {
  .packages_container {
    padding: 32px;
  }
  .title_packages {
    font-size: 32px;
  }
}
@media screen and (max-width: 420px) {
  .title_packages {
    font-size: 26px;
  }
  .packages_card_header {
    font-size: 16px;
  }
  .packages_card_text {
    font-size: 14px;
  }
}
