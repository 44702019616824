.payment_section_title {
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */

  display: flex;
  align-items: center;

  /* Base/Black100 */

  color: #2c3131;
}
.detail_payment {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  /* identical to box height, or 29px */

  display: flex;
  align-items: center;

  /* color: #ce3838; */
}
.qris_wrapper {
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .qris_wrapper {
    justify-content: start;
    align-items: start;
  }
}
