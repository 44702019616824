@import url("https://fonts.googleapis.com/css?family=Lato");

/* * {
  box-sizing: border-box;
}

h1 {
  text-align: center;
}

#app {
  padding: 0 20px;
  width: 100%;
} */

.timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
  padding: 0 16px;
}

.timeline-container::after {
  background-color: #182b63;
  content: "";
  position: absolute;
  left: calc(50% - 4px);
  width: 8px;
  height: 100%;
  border-radius: 24px;
}

.timeline-card {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  margin: 10px 0;
  width: 50%;
}

.timeline-card:nth-child(even) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-left: 30px;
  padding-right: 0;
}

.timeline-card-content {
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.35);
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: left;
}

.number-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

/* number big circle with number in the middle in white */
.number-section .number {
  background-color: #182b63;
  border-radius: 50%;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
  text-align: center;
  width: 50px;
}

.timeline-card-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-card:nth-child(even) .timeline-card-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.timeline-card-content p {
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  max-width: 250px;
}

.timeline-card-content .circle {
  background-color: #fff;
  border: 6px solid #182b63;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 10px);
  right: -40px;
  width: 20px;
  height: 20px;
  z-index: 100;
}

.timeline-card:nth-child(even) .timeline-card-content .circle {
  right: auto;
  left: -40px;
}

@media only screen and (max-width: 1023px) {
  .timeline-card-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .timeline-card-content,
  .timeline-card:nth-child(even) .timeline-card-content {
    padding: 15px 10px;
  }

  .timeline-container {
    padding: 16px;
  }

  /* the card will now take up full width */
  .timeline-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0px;
  }

  /* everything left aligned */
  .timeline-card:nth-child(even) {
    align-self: flex-start;
    justify-content: flex-start;
    padding-left: 0;
    padding-right: 0;
  }

  /*  bye bye circles */
  .timeline-card:nth-child(odd) .timeline-card-content .circle {
    display: none;
  }
  .timeline-card:nth-child(even) .timeline-card-content .circle {
    display: none;
  }
  /* the line */
  .timeline-container::after {
    left: 47px;
    z-index: -1;
    top: 0px;
  }

  /* and the arrows */
  .timeline-card:nth-child(odd) .timeline-card-content::after {
    display: none;
  }
  .timeline-card:nth-child(even) .timeline-card-content::after {
    display: none;
  }
}

