.table_filter_option {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #525256;
  width: 70px !important;
  height: 40px !important;
}
.table_filter_option.active {
  box-sizing: border-box;
  padding: 8px;
  gap: 8px;
  width: 70px;
  height: 40px;
  border-bottom: 2px solid #142657;
  background: transparent !important;
  color: #142657 !important;
}
.table_filter {
  max-width: 357px;
}
.card_table_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 28px 56px;

  max-width: 1029px;
  height: 415px;

  /* White */

  background: #ffffff;
  /* Shadow 01 */

  box-shadow: 0px 20px 25px rgba(76, 103, 100, 0.1);
  border-radius: 16px;
}
.table_pagination {
  max-width: 1029px;
}
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  gap: 0.5rem;
}
li.page-item {
  border-radius: 10px;
}

.table-responsive {
  width: 100% !important;
  padding: 24px 0px 16px;
}
table.table {
  height: 384px;
  padding: 24px 0px 16px;
  gap: 72px;
}
th.table_title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: capitalize;
  color: #191919;
}
.table_content {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  /* identical to box height, or 150% */

  text-transform: capitalize;
  /* Default Black */
  color: #191919;
}
.status_payment_failed {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;
  text-transform: capitalize;

  color: #e25c5c;
}
.status_payment_success {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;
  text-transform: capitalize;

  color: #54b435;
}
.status_payment_pending {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: right;
  text-transform: capitalize;

  color: #dfd249;
}
