.content_contaier_active {
  transition: width 0.3s ease-out;
  /* margin-left: 20%; */
}
.content_contaier_nonactive {
  transition: width 0.3s ease-out;
  /* margin-left: 20%; */
}
.layout_dashboard {
}
