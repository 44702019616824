.bpjs_card {
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-image: url("../../../assets/icons/CardBackgroundTwo.png");
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 500px;
  max-height: 250px;
  height: 248px;
  /* position: relative;  */
  /* overflow: hidden; */
}
/* 
.blurred {
  filter: blur(5px);
} */

/* .bpjs_card:hover {
  filter: blur(5px);
}

.download_button {
  transform: translate(156%, -325%);
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1;
} */

.bpjs_card:hover .download_button {
  display: block;
}

.download_button:hover {
  filter: none; /* Remove the blur effect on the download button when hovered */
}

.bpjs_card.unverify {
  /* height: 250px; */
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-image: url("../../../assets/icons/CardBackgroundTwo.png");
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 500px;
  max-height: 250px;
  height: 248px;
  filter: blur(5px);
}
.card_header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.2em;
  color: #b1e455;
}

.card_body {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 900;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.2em;

  color: #ffffff;
}
.card_footer {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.2em;
  color: #b1e455;
}
.upper_card.d-flex.flex-column {
  gap: 20px;
}
.card_left {
  gap: 56px;
}

.card_right {
  gap: 12px;
}
.bpjs_logo_card {
  max-width: 150px;
  max-height: 34px;
}

@media screen and (max-width: 576px) {
  .bpjs_card {
    max-width: 310px;
    width: 310px;
    height: auto;
  }
  .upper_card.d-flex.flex-column {
    gap: 0px;
  }
  .card_left {
    gap: 5px !important;
  }
  .title_card {
    font-size: 10px;
  }
  .desc_card {
    font-size: 12px;
  }
  .exp_card {
    font-size: 10px;
  }
  .status_btn {
    width: 75px;
    font-size: 10px;
    display: flex;
    height: 18px;
    justify-content: center;
    align-items: center;
  }
  .bpjs_logo_card {
    max-width: 98px;
  }
  /* .download_button {
    transform: translate(85%, -250%);
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1; 
  } */
}
