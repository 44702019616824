.sidebar-closed {
  width: 0;
  transition: width 0.5s ease-in-out;
  overflow-x: hidden;
}

.sidebar-open {
  width: 100% !important;
  transition: width 0.5s ease-out;
}
.sidebar_wrapper {
  width: 100% !important;
  transition: width 0.5s ease-out;
  height: 100vh;
}
/* .sidebar_wrapper {
  width: auto !important;
  transition: width 0.5s ease-out;
  position: fixed;
  background: white;
  z-index: 10;
  border-right: 2px solid;
  height: 100vh;
} */
.sidebar_item_title {
  max-width: 248px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 180%;
  display: flex;
  align-items: center;
  color: #2c3131;
  text-decoration: none;
}

.sidebar_item_wrapper_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar_item.active {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  gap: 11px;
  width: auto;
  height: 38px;

  background: #142657;
  border-radius: 10px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-decoration: none;
  color: white;
  transition: width 0.5s ease-out;
}
.sidebar_item_wrapper {
  gap: 2rem;
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease-out;
}

.sidebar_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  gap: 11px;
  width: auto;
  height: 38px;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 180%;
  color: #2c3131;
  text-decoration: none;
  transition: width 0.5s ease-out;
}

.sidebar_item_footer {
  margin-top: 100%;
}
.sidebar_item_logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 18px;
  gap: 11px;
  width: 248px;
  height: 38px;

  background: #e25c5c;
  border-radius: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 180%;
  text-decoration: none;
  color: white;
}
.sidebar_item_text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
}

@media screen and (max-width: 992px) {
  .sidebar_wrapper {
    width: 100px !important;
    /* transition: width 0.5s ease-out; */
    height: 100vh;
  }
  div#sidebar {
    transition: width 0.5s ease-out;
  }
  .sidebar_item_wrapper_title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar_item_title {
    display: none !important;
  }
  .sidebar_item.active {
    width: auto;
  }
  .sidebar_item_logout {
    width: auto;
  }
  .sidebar_item_text {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .sidebar_container {
    padding-right: 0px;
  }
  .row.flex-nowrap.row_sidebar {
    --bs-gutter-x: 0.5rem !important;
  }
  .sidebar_wrapper {
    width: 100px !important;
    transition: width 0.5s ease-out;
    height: 200vh;
    position: fixed;
    z-index: 9;
    background: white;
  }
  .sidebar-closed {
    height: 200vh;
    /* transition: width 0.3s ease-in-out; */
    /* overflow-x: hidden; */
  }
}
